import React from 'react'
import styled from 'styled-components'
import HeadingWithUnderbar from '../atoms/HeadingWithUnderbar'
import ImageAndText from '../modules/containers/NumberdContent'
import breakpoints from '../breakpoints'
import { WidthRestructor } from '../tokens/sizing'
import { flex } from '../tokens/positionings'
const Component = ({ data }) => {
  const contents = []
  let num = 1
  for (let content of data) {
    content = content.node
    contents.push(
      <ImageAndText
        number={num}
        title={content.title}
        text={content.text}
        image={content.image.url}
      />
    )
    num++
  }

  return (
    <Section>
      <HeadingWithUnderbar
        title="Our Business"
        subTitle="事業内容"
        margin={80}
      />
      <WidthRestructor>
        <Container>{contents}</Container>
      </WidthRestructor>
    </Section>
  )
}

const Section = styled.section`
  text-align: center;
  width: 100%;
  padding: 60px 40px;
  border-top: 10px solid #da6862;
  border-bottom: 10px solid #da6862;
  ${breakpoints.lessThan('pc')`
  padding: 20px;
`}
`

const Container = styled.ul`
  ${flex('space-between')}
`

export default Component
