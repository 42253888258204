import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'
import { fixRatio } from '../../tokens/sizing'
// import colors from '../../tokens/colors'
// import {ButtonWithRightArrow} from '../../atoms/buttons/LinkButtons'

const Component = props => {
  return (
    <Container>
      <StyledLink>
        <div>
          <figure>
            <img src={props.image} alt={props.title} />
          </figure>
          {/* <ButtonWithRightArrow fill={colors.cl_red} ><span></span>More</ButtonWithRightArrow> */}
        </div>
      </StyledLink>
      <TextContainer>
        <h5>
          <span>0{props.number}</span>
          {props.title}
        </h5>
        <p>{props.text}</p>
      </TextContainer>
    </Container>
  )
}

const Container = styled.li`
  width: 47%;
  margin-bottom: 80px;
  letter-spacing: 0.04em;
  ${breakpoints.lessThan('pc')`
  width: 100%;
  margin-bottom: 60px;
`}
`

const StyledLink = styled.div`
  display: block;
  text-align: left;
  > div {
    position: relative;
    margin-bottom: 12px;
    figure {
      ${fixRatio('1/1')};
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        object-fit: cover;
        object-position: center;
      }
    }
  }
`
const TextContainer = styled.div`
  text-align: left;
  h5 {
    font-size: 2.4rem;
    font-weight: 900;
    line-height: 36px;
    display: flex;
    flex-flow: row no-wrap;
    align-items: center;
    > span {
      display: block;
      margin-right: 14px;
      font-family: DIN Condensed;
      font-weight: bold;
      font-size: 9.4rem;
      line-height: 113px;
    }
  }
  p {
    font-weight: 900;
    font-size: 1.9rem;
    line-height: 200%;
    letter-spacing: 0.14em;
  }
`

export default Component
