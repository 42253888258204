import React from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../tokens/fontStyles'
import ImageAndText from '../modules/containers/HorizontalContent'
import breakpoints from '../breakpoints'

const Component = ({ data }) => {
  const contents = []
  for (let content of data) {
    content = content.node
    contents.push(
      <ImageAndText
        title={content.title}
        text={content.text}
        image={content.image.url}
      />
    )
  }
  return (
    <Section>
      <h2>超成長マーケット 観光市場の可能性とは</h2>
      {contents}
    </Section>
  )
}

const Section = styled.section`
  padding: 40px 40px 0;
  max-width: 980px;
  margin: 0 auto;
  ${breakpoints.lessThan('pc')`
  padding: 20px;
`}
  h2 {
    ${SectionTitle};
    ${breakpoints.lessThan('pc')`
    text-align:center;
    margin-bottom:40px;
  `}
  }
`
export default Component
