import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../breakpoints.js'
import colors from '../../tokens/colors'
import { HeadingWithLeftbar } from '../../tokens/fontStyles'

const Component = props => {
  return (
    <Card>
      <section>
        <h5>{props.title}</h5>
        <p>{props.text}</p>
      </section>
    </Card>
  )
}

export default Component

const Card = styled.li`
  width: 31%;
  padding: 20px;
  margin-bottom: 80px;
  border: 1px solid ${colors.cl_red};
  border-radius: 12px;
  text-align: left;
  h5 {
    ${HeadingWithLeftbar}
    margin-bottom: 20px;
    padding-left: 6px;
    font-size: 1.8rem;
    line-height: 27px;
  }
  p {
    font-size: 1.4rem;
    line-height: 180%;
    letter-spacing: 0.04em;
    white-space: pre-wrap;
  }
  ${breakpoints.lessThan('pc')`
    width: 100%;
    margin-bottom: 20px;
		&:last-of-type{
			margin-bottom: 80px;
		}
  `}
`
