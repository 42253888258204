import React from 'react'
import styled from 'styled-components'
import Article from '../modules/containers/HorizontalContent'
import breakpoints from '../breakpoints'
import HeadingWithUnderbar from '../atoms/HeadingWithUnderbar'
import PlayButton from '../atoms/buttons/PlayButton'
import { HeadingBaseStyle } from '../tokens/fontStyles'
import { alignVertical } from '../tokens/positionings'
import video_image from '../../images/our_business03.jpg'
import YouTube from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

class Component extends React.Component {
  constructor ({data}) {
    super()
    this.data = data
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  createContent () {
    const contents = []
    for (let content of this.data) {
      content = content.node
      contents.push(
        <Article
          title={content.title}
          text={content.text}
          image={content.image.url}
        />
      )
    }
    return contents
  }
  openModal = () => {
    this.setState({isOpen: true})
  }
  render() {
    return (
      <Section id="styles">
        <HeadingWithUnderbar
          title="Our Style"
          subTitle="百楽荘ワークスタイル"
          margin={80}
        />
        <YouTube cchannel='youtube' isOpen={this.state.isOpen} videoId='Ock8vVsGDdE' onClose={() => this.setState({isOpen: false})} />
        <Video>
          <h4>INTERVIEWS</h4>
          <button onClick={this.openModal}>
            <PlayButton />
          </button>
        </Video>
        <div className='wrapper'>{ this.createContent() }</div>
      </Section>
    )
  }
}


const Section = styled.section`
  padding: 60px 40px;
  max-width: 980px;
  margin: 0 auto;
  ${breakpoints.lessThan('pc')`
  padding: 80px 20px;
`}
  > .wrapper {
    max-width: 980px;
    margin: 80px auto 0;
  }
`

const Video = styled.div`
  margin-bottom: 60px;
  background: url(${video_image}) no-repeat right center / cover;
  min-height: 350px;
  ${alignVertical};
  ${breakpoints.lessThan('pc')`
  margin-bottom: 40px;
`}
  h4 {
    margin-bottom: 20px;
    ${HeadingBaseStyle}
    color: white;
  }
`
export default Component
