import React from 'react'
import Layout from '../components/layout'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'
import Market from '../components/organisms/HorizontalContents'
import Business from '../components/organisms/NumberdContents.js'
import Style from '../components/organisms/BigImageAndContents'
import QA from '../components/organisms/FlexRoundedCards'

import SEO from '../components/seo'

export const query = graphql`
  {
    microcmsAbout {
      id
      title
      image {
        url
      }
    }
    allMicrocmsMarkets {
      edges {
        node {
          id
          title
          text
          image {
            url
          }
        }
      }
    }
    allMicrocmsBusinesses {
      edges {
        node {
          id
          image {
            url
          }
          title
          text
        }
      }
    }
    allMicrocmsStyles {
      edges {
        node {
          id
          title
          text
          image {
            url
          }
        }
      }
    }
    allMicrocmsQa {
      edges {
        node {
          id
          question
          answer
        }
      }
    }
  }
`
const IndexPage = ({ data }) => {
  const about = data.microcmsAbout
  return (
    <Layout>
      <SEO title="ABOUT" />
      <KeyVisual title={about.title} image={about.image.url} />
      <Market data={data.allMicrocmsMarkets.edges} />
      <Business data={data.allMicrocmsBusinesses.edges} />
      <Style data={data.allMicrocmsStyles.edges} id="styles" />
      <QA data={data.allMicrocmsQa.edges} />
    </Layout>
  )
}
export default IndexPage
