import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'
import { flex } from '../../tokens/positionings'
import { HeadingWithLeftbar, ArticleTextStyle } from '../../tokens/fontStyles'

const Component = props => {
  return (
    <Container>
      <section>
        <div>
          <h4>{props.title}</h4>
          <p>{props.text}</p>
        </div>
        <figure>
          <img src={props.image} alt={props.title} />
          <figcaption>{props.caption}</figcaption>
        </figure>
      </section>
    </Container>
  )
}

const Container = styled.li`
  margin-bottom: 60px;
  letter-spacing: 0.04em;
  &:last-of-type {
    margin-bottom: 80px;
    ${breakpoints.lessThan('pc')`
      margin-bottom: 60px;
    `}
  }
  ${breakpoints.lessThan('pc')`
      margin-bottom: 40px;
  `}
  section {
    ${flex('space-between')};
    div {
      width: 48%;
      margin-bottom: 20px;
      ${breakpoints.lessThan('pc')`
      width: 100%;
      margin-right: 0;
    `}
    }
    h4 {
      margin-bottom: 20px;
      ${HeadingWithLeftbar}
    }
    p {
      ${ArticleTextStyle}
    }
    figure {
      width: 48%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
      ${breakpoints.lessThan('pc')`
        width: 100%;
      `}
    }
  }
`

export default Component
