import React from 'react'
import styled from 'styled-components'
import { flex, alignVertical } from '../tokens/positionings'
import Title from '../atoms/HeadingWithUnderbar'
import RoundedCard from '../modules/cards/RoudedCard'
import breakpoints from '../breakpoints'

const Component = ({ data }) => {
  const contents = []
  for (let content of data) {
    content = content.node
    contents.push(
      <RoundedCard title={content.question} text={content.answer} />
    )
  }
  return (
    <Section>
      <Title title="Q&amp;A" subTitle="社員に聞いてみました" margin={60} />
      <CardList>{contents}</CardList>
    </Section>
  )
}

const Section = styled.section`
  text-align: center;
  max-width: 980px;
  margin: 0 auto;
  padding: 60px 40px;
  ${breakpoints.lessThan('pc')`
  padding: 0 20px;
`}
`
const CardList = styled.ul`
  ${flex('space-between')};
  ${breakpoints.lessThan('pc')`
  ${alignVertical}
`}
`

export default Component
